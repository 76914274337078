'use client'

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { useState } from 'react'
import {IoClose} from "react-icons/io5";

export function Modal(
    {
        isOpen = false,
        setIsOpen = () => {},
        parentClass,
        modalClass,
        title,
        titleClass,
        closeable = false,
        children,
    }) {

    return (
        <>
            <Dialog open={isOpen} onClose={() => closeable ? setIsOpen(false) : null} className={`relative z-[9999] bg-tg-bg-box ${parentClass}`}>
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-tg-background/50 duration-300 ease-out data-[closed]:opacity-0"
                />
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className={`relative max-w-lg p-6 ${title ? 'pt-14' : ''} duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 !bg-tg-bg-box rounded-xl border border-tg-border ${modalClass}`}
                    >
                        {closeable && (
                            <span onClick={() => setIsOpen(false)}
                                  className="absolute top-2 right-2 text-xl text-tg-text-normal hover:text-tg-text-title transition duration-150 cursor-pointer">
                                <IoClose/>
                            </span>
                        )}

                        {title && (
                            <h3 className={`font-termina font-semibold absolute top-4 left-6 text-base ${titleClass}`}>
                                {title}
                            </h3>
                        )}

                        {children}
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}
