import Link from "next/link";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="w-full bg-tg-bg-header py-[20px] border-t border-tg-border">
            <div className="container flex flex-col-reverse lg:flex-row items-center justify-between gap-3 lg:gap-0 text-xs font-semibold">
                <span className="text-tg-text-normal">
                    copyright ©{currentYear} Tripgate LLC. All rights reserved.
                </span>
                <span className="text-tg-text-title">
                    Washington DC, USA.
                </span>
                <span className="flex flex-col-reverse lg:flex-row items-center gap-4 lg:gap-0 lg:w-[288px] justify-end">
                    <div className="flex items-center gap-[2px]">
                        <span className="text-tg-text-normal">
                            Contact Us:
                        </span>
                        <a href='mailto:info@tripgate.us'>
                            <span className="text-tg-text-title">
                                info@tripgate.us
                            </span>
                        </a>
                    </div>

                    <div className="ms-3 flex items-center gap-2">
                        <a href="https://x.com/tripgateus" target='_blank' className='bg-white rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                                 viewBox="0 0 50 50">
                                <path
                                    d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/tripgateus" target='_blank' className='bg-white rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                                 viewBox="0 0 48 48">
                                <path fill="#0288D1"
                                      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path
                                                                fill="#FFF"
                                                                d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                            </svg>
                        </a>
                        <a href="https://www.youtube.com/channel/UCx5fe7JLi_iMnkGWBS3LU9w" target='_blank' className='bg-white rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                                 viewBox="0 0 48 48">
                                <path fill="#FF3D00"
                                      d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path><path
                                                                fill="#FFF" d="M20 31L20 17 32 24z"></path>
                            </svg>
                        </a>
                    </div>
                </span>
            </div>
        </footer>
    );
};

export default Footer;
