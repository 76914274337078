export const routes = [
    {
        id: 1,
        path: '/',
        label: 'Home',
        headerListShow: true,
    },
    {
        id: 2,
        path: '/services',
        label: 'Services',
        headerListShow: true,
    },
    {
        id: 3,
        path: '/consultation',
        label: 'Consultation',
        headerListShow: true,
    },
    {
        id: 4,
        path: '/contact',
        label: 'Contact',
        headerListShow: true,
    },
]
