'use client'

import {useRef, useState} from "react";
import {LuEye, LuEyeOff} from "react-icons/lu";

const SimpleInput = ( // Customized input
    {
        parentClass,
        label,
        labelClass,
        containerClass,
        id,
        type = 'text',
        placeholder,
        maxLength,
        changeHandler,
        value,
        inputClass,
        icon,
        errorMessage,
        children,
        ...otherProps
    }) => {
    const inputRef = useRef(null);
    const [showPass, setShowPass] = useState(false);

    const toggleShowPass = (e) => {
        e.stopPropagation(); // Stop effecting on input focus
        setShowPass(!showPass);
    }

    return (
        <div className={`${parentClass} w-full flex flex-col items-start gap-3 relative`}>
            {label && (
                <label className={`text-tg-text-title lg:text-xs xl:text-sm ms-4 ${labelClass}`} htmlFor={id}>
                    {label}
                </label>
            )}

            <div
                className={`w-full flex items-center gap-4 border border-tg-border rounded-lg p-4 lg:p-5 xl:p-6 cursor-text ${containerClass}`}
                onClick={() => inputRef.current?.focus()}
            >
                {icon && (
                    <span className='text-tg-text-title lg:text-base xl:text-lg'>
                        {icon}
                    </span>
                )}

                <input
                    ref={inputRef}
                    id={id}
                    name={id}
                    type={type !== 'password' ? type : (showPass ? 'text' : 'password')}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    value={value}
                    onChange={changeHandler}
                    className={`w-full text-sm xl:text-base bg-transparent border-none outline-none appearance-none text-tg-text-title placeholder:text-tg-text-normal ${inputClass}`}
                    {...otherProps}
                />

                {children}

                {type === 'password' && (
                    <span className='text-tg-text-title lg:text-base xl:text-lg cursor-pointer' onClick={toggleShowPass}>
                        {showPass ? <LuEyeOff/> : <LuEye/>}
                    </span>
                )}
            </div>

            {errorMessage && (
                <span className='text-[9px] lg:text-2xs xl:text-xs text-tg-bg-login-badge absolute -bottom-3.5 xl:-bottom-5 end-4'>
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default SimpleInput;
