// components/LoadingSpinner.js
import React, { useEffect, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa'; // Choose any icon you like from React Icons
import { gsap } from 'gsap';
import {ImSpinner, ImSpinner10} from "react-icons/im";

const SpinnerLoading = ({props, width= '2rem', height= '2rem'}) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}><circle cx={4} cy={12} r={1.5} fill="currentColor"><animate attributeName="r" dur="0.75s" repeatCount="indefinite" values="1.5;3;1.5"></animate></circle><circle cx={12} cy={12} r={3} fill="currentColor"><animate attributeName="r" dur="0.75s" repeatCount="indefinite" values="3;1.5;3"></animate></circle><circle cx={20} cy={12} r={1.5} fill="currentColor"><animate attributeName="r" dur="0.75s" repeatCount="indefinite" values="1.5;3;1.5"></animate></circle></svg>);
};

export default SpinnerLoading;

