import React from 'react';

const SimpleBtn = (
    {
        label,
        labelClass,
        icon,
        iconClass,
        btnClass,
        onClick,
        ...otherProps
    }) => {
    return (
        <button className={`flex items-center gap-2 bg-tg-bg-btn text-sm text-tg-text-title py-3 px-8 rounded-[10px] ${btnClass}`} onClick={onClick} {...otherProps}>
            {icon && (
                <span className='text-tg-text-title lg:text-base xl:text-lg'>
                    {icon}
                </span>
            )}
            <span className={`text-sm text-tg-text-title ${labelClass}`}>
                {label}
            </span>
        </button>
    );
};

export default SimpleBtn;
