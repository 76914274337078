const PageTitle = ({ parentClass, title, titleClass, subTitle, subTitleClass, icon, iconClass }) => {
    return (
        <div className={`flex flex-col items-center gap-4 xl:mx-[212px] pt-[100px] lg:pt-[120px] xl:pt-[160px] ${parentClass}`}>
            {icon && (
                <span className={`font-bold text-[4rem] text-tg-text-title ${iconClass}`}>
                    {icon}
                </span>
            )}
            {title && (
                <h1 className={`font-bold font-termina text-[22px] xl:text-2xl text-tg-text-title text-center ${titleClass}`}>
                    {title}
                </h1>
            )}
            {subTitle && (
                <p className={`lg:text-sm xl:text-lg text-tg-text-important text-center ${subTitleClass}`}>
                    {subTitle}
                </p>
            )}
        </div>
    );
};

export default PageTitle;
