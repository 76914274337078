'use client'

import PageTitle from "@/components/layout/page-title";
import {Modal} from "@/components/layout/modal";
import SimpleInput from "@/components/inputs/simple-input";
import SimpleBtn from "@/components/inputs/simple-btn";
import {useState} from "react";
import {changeHandler, fetchData} from "@/helpers/general-functions";
import SpinnerLoading from "@/components/layout/spinner-loading";
import {isValidChangePassword} from "@/validation/auth-schemas";
import {API_URL} from "@/helpers/variables";
import {toast} from "react-toastify";

const ChangePasswordModal = ({ isOpen, setIsOpen }) => {
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [changePassForm, setChangePassForm] = useState({
        "password": null,
        "confirm_password": null,
    });

    const onChangePassword = async (e) => {
        setLoading(true);
        const validation = await isValidChangePassword(changePassForm)

        if (validation?.isValid) {
            const changePassResponse = await fetchData(API_URL, 'auth/reset-password/', 'post', {...changePassForm}, true, {
                isHeaderJson: true,
            });

            if (changePassResponse.status === 200) {
                toast.success('Password changed successfully!');
                setIsOpen(false);

                setChangePassForm({
                    "password": "",
                    "confirm_password": ""
                });
            }
        } else {
            setErrorMessages(validation.errors)
        }
        setLoading(false);
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} closeable modalClass='!w-full !max-w-[500px]'>
            <div className="flex flex-col gap-6">
                <PageTitle
                    parentClass='!pt-0 lg:!pt-0 xl:!pt-0 xl:!mx-0'
                    titleClass='!text-lg xl:!text-lg'
                    subTitleClass='text-sm xl:text-sm'
                    title='Change Password'
                    subTitle='Password must be at least 10 characters, including uppercase and lowercase letters, numbers, and special characters.'
                />

                <SimpleInput
                    id='password'
                    changeHandler={(e) => changeHandler('password', e.target.value, setChangePassForm, changePassForm)}
                    value={changePassForm.password}
                    label='New Password'
                    labelClass='font-semibold'
                    placeholder='********'
                    type="password"
                    icon={
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M16 19V5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    }
                    errorMessage={errorMessages?.password}
                />
                <SimpleInput
                    id='confirm_password'
                    changeHandler={(e) => changeHandler('confirm_password', e.target.value, setChangePassForm, changePassForm)}
                    value={changePassForm.confirm_password}
                    label='Confirm New Password'
                    labelClass='font-semibold'
                    placeholder='********'
                    type="password"
                    icon={
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M16 19V5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                            <path d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                  stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    }
                    errorMessage={errorMessages?.confirm_password}
                />

                <div className="w-full flex justify-end">
                    {loading ?
                        (
                            <div className="flex items-center justify-center relative !cursor-wait">
                                <SimpleBtn
                                    label='Change Password'
                                />
                                <span className="absolute flex justify-center w-full bg-tg-bg-btn !cursor-wait">
                            <SpinnerLoading/>
                        </span>
                            </div>
                        ) : (
                            <SimpleBtn
                                label='Change Password'
                                onClick={onChangePassword}
                            />
                        )
                    }
                </div>
            </div>
        </Modal>
    );
};

export default ChangePasswordModal;
