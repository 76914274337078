import * as yup from "yup";

export const isValidChangePassword = async (form_data) => {
    const schema = yup.object().shape({
        password: yup.string()
            .required('Password is required')
            .min(10, 'Password must be at least 10 characters')
            .matches(/[a-z]/, 'Password must contain a lowercase letter')
            .matches(/[A-Z]/, 'Password must contain an uppercase letter')
            .matches(/\d/, 'Password must contain a number')
            .matches(/[@$!%*?&#^()\-_=+{};:,<.>]/, 'Password must contain a special character'),
        confirm_password: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password'),
    });

    try {
        await schema.validate(form_data, { abortEarly: false });
        return { isValid: true, errors: [] };
    } catch (e) {
        const errors = {};
        e.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};

export const isValidRegisterInfo = async (form_data) => {
    const schema = yup.object().shape({
        email: yup.string().required('Email is required').email('Email is invalid.'),
        password: yup.string()
            .required('Password is required')
            .min(10, 'Password must be at least 10 characters')
            .matches(/[a-z]/, 'Password must contain a lowercase letter')
            .matches(/[A-Z]/, 'Password must contain an uppercase letter')
            .matches(/\d/, 'Password must contain a number')
            .matches(/[@$!%*?&#^()\-_=+{};:,<.>]/, 'Password must contain a special character'),
        confirm_password: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password'),
    });

    try {
        await schema.validate(form_data, { abortEarly: false });
        return { isValid: true, errors: [] };
    } catch (e) {
        const errors = {};
        e.inner.forEach((err) => {
            errors[err.path] = err.message;
        });
        return { isValid: false, errors };
    }
};
